import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import image1 from '../../assets/images/Image-1.svg';
import logo from '../../assets/images/opec10.png'
import AuthSidebar from './Sidebar';
import loader1 from '../../assets/images/03-42-11-849_512.gif'
import '../../assets/styles/font_style.css';
import google from '../../assets/images/Social login with Apple, Google, or Facebook.png'
import axios from 'axios'
import useCheckInternet from '../generalComponents/checkOnline';
import ResetPassword from './ResetPassword';

const LoginForm = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState({})
    const [isPending, setIsPending] = useState(false)
    const [serverError, setServerError] = useState(null);
    const [hideTop, setHideTop] = useState(false);
    const [isReset, setIsReset] = useState(false)
    const {id} = useParams();
    const navigate = useNavigate()

    
    const validateField = (field, value) => {
        let error = '';
        if(value !== undefined){
            if (field === 'email') {
                // Basic email validation (adjust as needed)
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) {
                    error = 'Invalid email address';
                }
            }else if(field === 'password'){
                if (value.trim() === '') {
                    error = 'Password is required';
                }
            }
        }
        setError((prevErrors) => ({ ...prevErrors, [field]: error }));
    };

    const handleResetView =()=>{
        setIsReset(prevIsReset => !prevIsReset)
    }

    const isOnline = useCheckInternet();

    const handleLogin = async (e) => {
        e.preventDefault()
        // Check if the user is online
        if (!isOnline) {
            setServerError('You are offline. Please check your internet connection and try again.');
            return;
        }else if(!email || !password || email === " " || password ===" "){
            setServerError('Please fill in details.');
        }else{
            setIsPending(true);
            validateField('email', email);
            validateField('password', password);
            try {
            const hasErrors = Object.values(error).some((error) => error !== '');
            if (hasErrors) {
                setServerError('Login failed. Please try again....');
                setIsPending(false);
                return;
            }
            let data = { email, password }
            const response = await axios.post('https://www.opecstat.com/opecstat_server/user/login', JSON.stringify(data), {headers: { 'Content-Type': 'application/json' }});
                
            if (response.status === 200) {
                const token = response.data.token; // Assuming token is returned from backend
                localStorage.setItem('token', token);
                setServerError(response.data.message);
                setIsPending(false);
                console.log(response)
                console.log("Sending video ID to server:", id);
                if(id){
                    try{
                        const response = await axios.post('https://www.opecstat.com/opecstat_server/video/checkVideoExist',{video:id},{headers:{'Content-Type':'application/json'}});
                        const data = response.data

                        if (data.exists) {
                            // Navigate to lessons page if video data exists
                            console.log("Video found")
                            navigate(`/lesson/${id}`);
                        } else {
                            // Navigate to dashboard if video data does not exist
                            console.log("Video not found")
                            navigate('/dashboard');
                        }
                    } catch (error) {
                        //console.log('Error checking video data:', error)
                        //console.error('Error checking video data:', error);
                        navigate('/dashboard'); // Fallback in case of error
                    } 
                }else{
                    //console.log("Video and id not found")
                    navigate('/dashboard');
                }
            } else {
                setServerError(response.data.message); // Use response.data.message for more accurate error messages
                setIsPending(false);
                throw new Error(response.data.message)
            }
            } catch (error) {
                console.log('Login failed:', error.response.data.message);
                //alert(error.response.data.message)
                setServerError(error.response.data.message);
                setIsPending(false);
            }
        }
    };
    
    
    return (
        <section className="regForm h-100 w-full" style={{height:"100%", fontFamily:"Helvetica"}}>
            <div className='row pb-5'>
                <AuthSidebar/>
                <div className='col-md-6 pb-5'>
                    <center>
                        {
                            isReset === false  ? (
                                <div>
                                    {isPending && <div className='h-100 text-xxl mt-10' ><img src={loader1} alt='loader' /><br/><p className='fs-2' style={{color:"black"}}>Logging you in...</p></div>}
                                    {!isPending &&
                                        <div style={{width:'70%'}}>
                                            {
                                                !hideTop &&
                                                <center>
                                                <Link to="/"><h3 className='w-25 mt-5'><img src={logo} alt='opecstat logo'/></h3></Link>
                                                    <p className='my-5 text-xl font-mono' style={{color:"black"}}>
                                                        Opecstat offers a seamless and structured way to learn and improve your digital skill.
                                                    </p>
                                                </center>
                                            }

                                            <form className='mt-1'>
                                            {serverError && <div className=" alert alert-danger px-4 py-2 rounded-md">{serverError}</div>}
                                                <div className='mb-2'>
                                                    <input 
                                                        type="email" 
                                                        className="rounded-lg px-6 py-3 w-full" 
                                                        id="email" 
                                                        placeholder="Email Address"
                                                        onChange={(e) =>{ 
                                                            setEmail(e.target.value)
                                                            validateField('email', e.target.value);
                                                        }}
                                                        onBlur={() => validateField('email', email)}
                                                    />
                                                    {/*error.email && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.email}</p>*/}
                                                </div>
                                                <div className='mb-2'>
                                                    <input 
                                                        type="password" 
                                                        className="rounded-lg px-6 py-3 w-full" 
                                                        id="password" 
                                                        placeholder='Password'
                                                        onChange={(e)=>{
                                                            setPassword(e.target.value)
                                                            validateField('password', e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {/*<div className='mb-4'>
                                                    <select 
                                                        name="role" 
                                                        className="rounded-lg px-6 py-3 w-full text-dark" 
                                                        value={role} 
                                                        onChange={(e)=>{setRole(e.target.value)}} 
                                                        /*onBlur={() => validateField('role', role)}*
                                                        id="role"
                                                        placeholder="Role"
                                                    >
                                                        
                                                        <option disabled="disabled">Select Role</option>
                                                        <option value="student">Student</option>
                                                        <option value="instructor">Instructor</option>
                                                    </select>
                                                </div>*/}
                                                <div className='mb-2 d-grid'>
                                                    <button onClick={handleLogin} type="submit" style={{color:"#fff", backgroundColor:"#FF7518"}} className='btn btn-block btn-wangin hover:bg-primary py-2'>LOG IN</button>
                                                </div>
                                            </form>
                                            <center className='text-gray-600 my-6'>
                                                <div className='mt-2 mb-1'>
                                                    Forgot Password? <span onClick={handleResetView}  className='text-primary text-decoration-underline cursor-pointer'>Click here</span>
                                                </div>
                                                -------- OR -------
                                            </center>
                                                    
                                            <div className='mb-5' style={{color:"black"}}>
                                                Don't yet have an account? <Link to="/register" className='text-primary text-decoration-underline cursor-pointer'>Create an Account</Link>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ):(
                                <div>
                                    <ResetPassword handleResetView={handleResetView}/>
                                    <div className='mt-2 mb-1'>
                                        <span onClick={handleResetView}  className='text-primary text-decoration-underline cursor-pointer'>Back to Login</span>
                                    </div>
                                </div>
                            )
                        }
                    </center>
                </div>
            </div>
        </section>
    );
}

export default LoginForm;
