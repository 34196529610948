import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import YouTubeEmbed from './utilityComponents/YoutubeEmbed';
import logo from '../../assets/images/opec10.png'
import useFetch from '../custom_hooks/useFetch';
import ResourceControlComponent from './utilityComponents/ResourceControl';

const WebinarView = ({renderView = null, userData = null, videoData}) =>{
    const [videoId, setVideoId] = useState(null);

    const {
        result,
        isResultPending,
        resultError,
    } = useFetch(videoId ? `https://opecstat.com/opecstat_server/learnResource/getLearnResourceByVideoId/${videoId}` : null);

    useEffect(() => {
        // Check if videoData._id is available before setting videoId
        if (videoData && videoData._id) {
            setVideoId(videoData._id);
        }
    }, [videoData]); // Watch videoData for changes


    const handleResourceClick = (type) => {
        if (isResultPending) {
            alert("result are being loaded...");
        } else if (resultError) {
            alert(`An error occurred: ${resultError.message || resultError}`);
        } else if (!result || result.length === 0) {
            alert("No result found.");
        } else {
            //console.log("result:", JSON.stringify(result, null, 2));
            // Handle resource logic here
        }
    };


    return(
        <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        {videoData && <h1 className="h3 mb-0 text-gray-800">{videoData.title}</h1>}
                        {/*<a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                                className="fas fa-download fa-sm text-white-50"></i> Generate Report</a>*/}
                </div>
                
                <div className='row'>
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mb-4">
                            {/*<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">Active Milestones</h6>
                                <div className="dropdown no-arrow">
                                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                        aria-labelledby="dropdownMenuLink">
                                        <div className="dropdown-header">Dropdown Header:</div>
                                        <a className="dropdown-item" href="#">Action</a>
                                        <a className="dropdown-item" href="#">Another action</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="card-body">
                                <div className="milestone_area">
                                    {!videoData && <center><img src={logo} alt='logo' style={{width:"30%", height:"25%", opacity:"25%"}}/><br/><b>Loading Lesson...</b></center>}
                                    {videoData && <YouTubeEmbed videoData={videoData} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div class="col-xl-4 col-lg-4">
                        
                        <div className='border-1 mb-2'>
                            <ResourceControlComponent videoData={videoData} userData={userData} type="chat"/>
                        </div>
                        <div class="card shadow mb-4">
                            <div
                                class="card-header pb-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 class="m-0 font-weight-bold text-primary">Important Resources</h6>
                                <div class="dropdown no-arrow">
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                        aria-labelledby="dropdownMenuLink">
                                        <div class="dropdown-header">Dropdown Header:</div>
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body overflow-auto">
                                <div class="chart-pie pt-1 pb-3">
                                    {videoData &&
                                    <div className='mb-3'>
                                        <div className='border-1 mb-1'>
                                            <ResourceControlComponent videoData={videoData} userData={userData} type="textbook"/>
                                        </div>
                                        <div className='border-1 mb-1'>
                                            <ResourceControlComponent videoData={videoData} userData={userData} type="quiz"/>
                                        </div>
                                        <div className='border-1 mb-1'>
                                        <ResourceControlComponent videoData={videoData} userData={userData} type="assignment"/>
                                        </div>
                                        <div className='border-1 mb-1'>
                                        <ResourceControlComponent videoData={videoData} userData={userData} type="sourceCode"/>
                                        </div>
                                    </div>
                                    }
                                </div><br/><br/>
                            </div>
                        </div>
                    </div>*/}
                </div>

                {/*<div className='row'>
                    <div class="card shadow mb-4">
                        <div
                            class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Recommendations</h6>
                            <div class="dropdown no-arrow">
                                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                    aria-labelledby="dropdownMenuLink">
                                    <div class="dropdown-header">Dropdown Header:</div>
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="chart-pie pt-4 pb-2">
                                <Recommendation/>
                            </div>
                        </div>
                    </div>
                </div>*/}
                 
        </>
    )
}

export const ViewOfferList = ({name, image, skill, rate, renderView}) =>{
    return(
        <>
            <div className='row bg-light border mb-1 rounded p-2 cursor-pointer' onClick={()=>{renderView('view_offers')}}>
                <div className='col-md-4'>
                    <img src={image} alt="User Image" className="img-fluid mb-3 rounded" />
                </div>
                <div className='col-md-8'>
                    <span><b>Name:</b> {name}</span><br/>
                    <span><b>Skill: </b>{skill}</span><br/>
                    <span><b>Rating: </b>{rate}</span>
                </div>
            </div>
        </>
    )
}

export default WebinarView