import {useState, useEffect} from 'react';
import axios from 'axios';

const useFetch = (url) => {
    const [result, setResult] = useState([])
    const [isResultPending, setIsResultPending] = useState(true)
    const [resultError, setResultError] = useState(null);
    useEffect(() => {
        const abortCont = new AbortController();
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(url,{signal: abortCont.signal} );
                console.log(response)
                if (response.status !== 200) {
                    throw new resultError('Failed to fetch blogs');
                }
                setResult(response.data);
                setIsResultPending(false)
                setResultError(null)
            } catch (e) {
                if(e.name === 'AbortresultError'){
                    console.log("Fetch Aborted")
                }else{
                    setResultError(e.message);
                    setIsResultPending(false)
                }
            }
        };
        fetchBlogs();
        return ()=> abortCont.abort;
    }, [url]);

    return {result, isResultPending, resultError}
}
 
export default useFetch;