import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import useFetch from '../../custom_hooks/useFetch';
import subImage from '../../../assets/images/wo.avif';
import ChatComponent from './ChatBox';

const ResourceControlComponent = ({ videoData, userData, type }) => {
  const [showModal, setShowModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [quizData, setQuizData] = useState([]);
  const [quizAnswers, setQuizAnswers] = useState({});
  const [quizResult, setQuizResult] = useState(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const { result, isResultPending, resultError } = useFetch(
    videoId ? `https://opecstat.com/opecstat_server/learnResource/getLearnResourceByVideoId/${videoId}` : null
  );

  useEffect(() => {
    if (videoData && videoData._id) {
      setVideoId(videoData._id);
    }
  }, [videoData]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.flutterwave.com/v3.js';
    script.async = true;
    script.onload = () => setIsScriptLoaded(true);
    script.onerror = () => alert('Failed to load payment gateway. Please try again later.');
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  const handleSubscription = async () => {
    if (!isScriptLoaded) {
      alert('Payment gateway not ready. Please wait and try again.');
      return;
    }
    const paymentData = {
      tx_ref: `tx-${Math.floor(Math.random() * 1000000)}`,
      amount: 5000,
      currency: "NGN",
      payment_type: "card",
      email: userData.email,
      name: userData.name,
      custom_fields: [{ display_name: userData.name, variable_name: "user_id", value: userData._id }],
    };
    window.FlutterwaveCheckout({
      public_key: "FLWPUBK-3ac77063a52ca467ead928d3af1948f6-X",
      tx_ref: paymentData.tx_ref,
      amount: paymentData.amount,
      currency: paymentData.currency,
      payment_plan: 129677,
      payment_options: "card, banktransfer, ussd",
      customer: { email: paymentData.email, phone_number: "08139582196", name: paymentData.name },
      customizations: { title: "Opecstat Subscription", description: "Subscribe for premium access", logo: "https://yourwebsite.com/logo.png" },
      callback: function (data) {
        if (data.status === "successful") {
          fetch('https://opecstat.com/opecstat_server/user/subscribe', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId: userData._id }),
          })
          .then(response => response.ok ? alert("Subscription successful!") : alert("Failed to update subscription status."))
          .catch(() => alert("An error occurred while updating your subscription."));
        } else alert("Payment was not successful.");
      },
      onclose: function() { console.log("Payment cancelled!"); }
    });
  };

  const fetchQuizQuestions = async () => {
    try {
      const response = await fetch(`https://opecstat.com/opecstat_server/quiz/getQuizByVideoId/${videoId}`);
      const data = await response.json();
      setQuizData(data.questions);
    } catch (error) {
      console.error("Error fetching quiz questions:", error);
    }
  };

  const handleQuizAnswerChange = (questionId, answer) => {
    setQuizAnswers(prevAnswers => ({ ...prevAnswers, [questionId]: answer }));
  };

  const handleQuizSubmit = () => {
    const result = quizData.map(question => ({
      question: question.text,
      correct: quizAnswers[question._id] === question.correctAnswer
    }));
    setQuizResult(result);
  };

  const handleModalShow = () => {
    if (type === "quiz") fetchQuizQuestions();
    setShowModal(true);
  };
  const handleModalClose = () => setShowModal(false);
  const handleChatModalShow = () => setShowChatModal(true);
  const handleChatModalClose = () => setShowChatModal(false);

  return (
    <div>
      {type === "textbook" ? (
        <Button onClick={handleModalShow} className="btn btn-warning btn-sm rounded-lg p-3 flex-fill fw-bold text-white w-100">
          <center><i className="fa fa-fw fa-book fs-2"></i><br /><small>Download Textbooks for {videoData?.sub_course_id?.title}</small></center>
        </Button>
      ) : type === "quiz" ? (
        <Button onClick={handleModalShow} className="btn btn-success btn-sm rounded-lg p-3 flex-fill fw-bold text-white w-100">
          <center><i className="fa fa-fw fa-question-circle fs-2"></i><br />Take Lesson Quiz</center>
        </Button>
      ) : type === "assignment" ? (
        <Button onClick={handleModalShow} className="btn btn-dark btn-sm rounded-lg p-3 flex-fill fw-bold text-white cursor-pointer w-100">
          <center>
            <i className="fa fa-fw fa-file-alt fs-2"></i>
            <br />
            View Assignment
          </center>
        </Button>
      ) : type === "sourceCode" ? (
        <Button onClick={handleModalShow} className="btn btn-danger btn-sm rounded-lg p-3 flex-fill fw-bold text-white cursor-pointer w-100">
          <center>
            <i className="fa fa-fw fa-pencil-alt fs-2"></i>
            <br />
            Download Source Code
          </center>
        </Button>
      ) : type === "chat" ? (
        <Button onClick={handleChatModalShow} className="btn btn-primary rounded-lg p-3 flex-fill fw-bold text-white cursor-pointer w-100">
          <center>
            <i className="fa fa-fw fa-comment fs-2"></i>
            <br />
            Ask Questions
          </center>
        </Button>
      ) : type === "subscribe" ? (
        <Button onClick={handleModalShow} className="btn btn-info rounded-lg p-3 flex-fill fw-bold text-white cursor-pointer w-100">
          <center>
            <i className="fas fa-arrow-right"></i> Subscribe Now
          </center>
        </Button>
      ) : null}


      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton><Modal.Title>{type === "quiz" ? "Quiz" : "Resource Details"}</Modal.Title></Modal.Header>
        <Modal.Body>
          {type === "quiz" ? (
            <Form>
              {quizData.map((question) => (
                <div key={question._id}>
                  <h5>{question.text}</h5>
                  {question.options.map((option, index) => (
                    <Form.Check key={index} type="radio" name={question._id} label={option} 
                                onChange={() => handleQuizAnswerChange(question._id, option)} />
                  ))}
                </div>
              ))}
              {
                quizData.length !== 0 ? (
                  <Button onClick={handleQuizSubmit}>Submit Quiz</Button>
                ):(
                  userData.sub_status === 3?(
                    <>
                      {quizResult && (
                        <div>
                          <h5>Results:</h5>
                          {quizResult.map((result, index) => (
                            <div key={index}>{result.question} - {result.correct ? "Correct" : "Incorrect"}</div>
                          ))}
                        </div>
                      )}
                      <Button onClick={handleQuizSubmit}>Submit Quiz</Button>
                    </>
                  ):(
                      
                    <div className="row text-dark" style={{ overflow: "auto" }}>
                    <div className="h-75 text-center">
                      <center><img src={subImage} className="h-100 w-75 img-fluid mb-4" alt="side_image" /></center>
                      <h3 className="fw-bold">Stay Ahead in Tech!</h3>
                      <h5 className="mb-4">Unlock access to premium features for just ₦5,000/month</h5>
                    </div>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item"><i className="fas fa-infinity text-primary"></i> Lifetime Access to All Courses enrolled for</li>
                      <li className="list-group-item"><i className="fas fa-comment text-secondary"></i> Unlimited Questions and Interactions with Lucy</li>
                      <li className="list-group-item"><i className="fas fa-book-open text-success"></i> Unlimited Access to High-Value Textbooks</li>
                      <li className="list-group-item"><i className="fas fa-question text-warning"></i> Test Your Knowledge with Engaging Quizzes</li>
                      <li className="list-group-item"><i className="fas fa-tasks text-info"></i> Access to Assignments to Sharpen Your Skills</li>
                      <li className="list-group-item"><i className="fas fa-code text-danger"></i> Downloadable Source Code for Each Class</li>
                      <li className="list-group-item"><i className="fas fa-video text-secondary"></i> Exclusive Video Tutorials and Live Sessions</li>
                      <li className="list-group-item"><i className="fas fa-trophy text-primary"></i> Certification Upon Completion of Each Course</li>
                      <li className="list-group-item"><i className="fas fa-users text-success"></i> Access to an Active Community of Learners</li>
                    </ul>
                    {/* Subscribe Button */}
                    <div className="text-center">
                      <button onClick={handleSubscription} className="btn btn-success btn-lg">
                        <i className="fas fa-arrow-right"></i> Subscribe Now
                      </button>
                    </div>
                  </div>
                  ) 
                )
              }
              
                
              
            </Form>
          ) : (
            isResultPending ? (
              <div>Loading results...</div>
            ) : result ? (
              userData.sub_status === 3 ? (
                result.resources?.length > 0 ? (
                  result.resources.filter((item) => item.type === type).map((filteredItem) => (
                    <div className="card mb-3" key={filteredItem._id}>
                      <div className="card-header bg-secondary text-white">
                        <h5 className="card-title">{filteredItem.title}</h5>
                      </div>
                      <div className="card-body">
                        <div className="m-1">
                          {filteredItem?.desc}
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                          <a target="_blank" rel="noopener noreferrer" href={filteredItem.link} className="btn btn-success">Download {filteredItem.title}</a>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No resource available</div>
                )
              ) : (
                <div className="row text-dark" style={{ overflow: "auto" }}>
                  <div className="h-75 text-center">
                    <center><img src={subImage} className="h-100 w-75 img-fluid mb-4" alt="side_image" /></center>
                    <h3 className="fw-bold">Stay Ahead in Tech!</h3>
                    <h5 className="mb-4">Unlock access to premium features for just ₦5,000/month</h5>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item"><i className="fas fa-infinity text-primary"></i> Lifetime Access to All Courses enrolled for</li>
                    <li className="list-group-item"><i className="fas fa-comment text-secondary"></i> Unlimited Questions and Interactions with Lucy</li>
                    <li className="list-group-item"><i className="fas fa-book-open text-success"></i> Unlimited Access to High-Value Textbooks</li>
                    <li className="list-group-item"><i className="fas fa-question text-warning"></i> Test Your Knowledge with Engaging Quizzes</li>
                    <li className="list-group-item"><i className="fas fa-tasks text-info"></i> Access to Assignments to Sharpen Your Skills</li>
                    <li className="list-group-item"><i className="fas fa-code text-danger"></i> Downloadable Source Code for Each Class</li>
                    <li className="list-group-item"><i className="fas fa-video text-secondary"></i> Exclusive Video Tutorials and Live Sessions</li>
                    <li className="list-group-item"><i className="fas fa-trophy text-primary"></i> Certification Upon Completion of Each Course</li>
                    <li className="list-group-item"><i className="fas fa-users text-success"></i> Access to an Active Community of Learners</li>
                  </ul>
                  {/* Subscribe Button */}
                  <div className="text-center">
                    <button onClick={handleSubscription} className="btn btn-success btn-lg">
                      <i className="fas fa-arrow-right"></i> Subscribe Now
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div>Error fetching resources: {resultError}</div>
            )
          )}
        </Modal.Body>
        <Modal.Footer><Button variant="secondary" onClick={handleModalClose}>Close</Button></Modal.Footer>
      </Modal>

      {/* Chat Modal */}
      <Modal show={showChatModal} onHide={handleChatModalClose}>
        <Modal.Header closeButton><Modal.Title><b>Chat with Lucy</b></Modal.Title></Modal.Header>
        <Modal.Body><ChatComponent user={userData} video={videoData} /></Modal.Body>
        <Modal.Footer><Button variant="secondary" onClick={handleChatModalClose}>Close</Button></Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResourceControlComponent;
