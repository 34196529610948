import React, { useState, useEffect } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LessonCard = ({ userData }) => {
    const [coursesGrouped, setCoursesGrouped] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const videosWatched = userData?.vids_watched || [];

                const courses = videosWatched.reduce((acc, video) => {
                    const mainCourseId = video.main_course_id?._id || 'Uncategorized';
                    if (!acc[mainCourseId]) {
                        acc[mainCourseId] = {
                            mainCourseTitle: video.main_course_id?.title || 'Uncategorized',
                            videos: []
                        };
                    }
                    acc[mainCourseId].videos.push(video);
                    return acc;
                }, {});

                setCoursesGrouped(Object.values(courses));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user videos:', error);
                setLoading(false);
            }
        };

        fetchCourses();
    }, [userData]);

    if (loading) {
        return <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>;
    }

    return (
        <div className="container mt-4">
            <h3>Your Courses</h3>
            <div className="row">
                {coursesGrouped.map((course, index) => (
                    <div className="col-md-4" key={index}>
                        <Card className="mb-4 shadow-sm">
                            <Card.Body>
                                <Card.Title>{course.mainCourseTitle}</Card.Title>
                                <Link
                                    to={`/lesson/${course.videos[0]._id}`} // Link to the first video in the course
                                    className="btn btn-primary"
                                >
                                    Start Course
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LessonCard;
